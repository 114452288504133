<template>
  <b-card
    ><b-card-title> {{ title }} </b-card-title>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :pagination-options="{
        enabled: false,
      }"
      :totalRows="totalRecords"
      :rows="rows"
      :columns="columns"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-per-page-change="onPerPageChange"
      :isLoading.sync="isLoading"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'PolicyEn'" class="d-flex">
          <label class="ml-1" v-html="props.row.policyEn"></label>
        </span>

        <span v-if="props.column.field == 'PolicyFr'" class="d-flex">
          <label class="ml-1" v-html="props.row.policyFr"></label>
        </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'" class="d-flex">
          <b-button
            variant="secondary"
            :to="{ name: 'update-privacy', params: { id: props.row._id } }"
            class="m-auto"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template #emptystate>
        <p class="text-center">
          {{
            isOPen ? "Loading..." : "This will show up when there are no rows"
          }}
        </p>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
  BButton,
  BCardTitle,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import PolicyService from "@/services/PolicyService";
import Modal from "@/views/components/shared/Modal/Modal";

export default {
  name: "ChatList",
  mixins: [],
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
    BCardTitle,
    BButton,
    Modal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      params: {
        page: 1,
        limit: 10,
        sort_keys: undefined,
        sort_dir: undefined,
        query: undefined,
      },
      columns: [
        { label: "English", field: "PolicyEn" },
        { label: "French", field: "PolicyFr" },
        { label: "Action", field: "action" },
      ],
      rows: [],
      isLoading: undefined,
      isOPen: true,
      totalRecords: undefined,
      policyService: new PolicyService(),
      show: false,
      title: "Privacy Policy",
      type: this.$route.fullPath.split("/").pop(),
    };
  },
  watch: {
    params: {
      handler: function () {
        this.getPolicy();
      },
      deep: true,
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getPolicy();
    if (this.type == "terms") {
      this.title = "Terms";
    }
    if (this.type == "guidelines") {
      this.title = "Guidelines";
    }
  },
  methods: {
    onPerPageChange(page) {
      this.params.page = 1;
      this.params.limit = page.currentPerPage;
    },
    onPageChange(active) {
      this.params.page = active.currentPage;
      this.params.limit = active.currentPerPage;
    },
    onSortChange(params) {
      const SortKey =
        typeof params[0].field === "function" ? "client" : params[0].field;
      this.params.sort_keys = [SortKey[0].toLowerCase() + SortKey.slice(1)];
      this.params.sort_dir = [params[0].type];
    },
    getPolicy() {
      this.params.type = this.type;
      this.isLoading = true;
      this.policyService
        .index(this.params)
        .then((res) => {
          this.rows = res.map((el) => {
            return {
              ...el,
            };
          });
          this.totalRecords = res.count;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
          this.isOPen = false;
        });
    },
  },
};
</script>
<style>
.first {
  background-color: #fce19eae;
}

.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}
@media (max-width: 576px) {
  th,
  td {
    /* font size */
    font-size: 0.857rem;
    font-weight: 400;
    /* text center horizontally */
    vertical-align: middle !important;
    text-align: center;
    line-height: 20px;
  }
}
</style>