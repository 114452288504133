import CrudService from "@/services/shared/http/CrudService";

export default class PolicyService extends CrudService {
  constructor(params = {}) {
    super("policy", params);
  }
  index(params = {}) {
    return this.api.GET("policy/get-policy", params)
  }
}
